import env from '@/plugins/env';
import { createHTTP } from '../api';

export const support = createHTTP(env.get('VUE_APP_SUPPORT_API'));

export default {
  getTopics(type) {
    return support
      .get(`/v3/topics`, { params: { type } })
      .then(res => res.data);
  },

  issueCreate(data) {
    return support.post(`/v3/issues`, data).then(res => res.data);
  },

  createAttachment(file, axiosParams) {
    const formData = new FormData();
    formData.append('file', file);

    return support
      .post(`/v3/issues/attachments`, formData, axiosParams)
      .then(res => res.data);
  },
};
