<template>
  <div>
    <v-autocomplete
      outlined
      multiple
      label="Actions"
      :items="allActions"
      :value="actions"
      :search-input.sync="search"
      @input="changeActions"
    >
      <template #selection="{ index }">
        <span v-if="index === 0" class="mr-2">
          Выбрано {{ actions.length }} прав
        </span>
      </template>
      <template #item="{ item, on }">
        <v-list-item-action>
          <v-simple-checkbox :value="actions.includes(item)" v-on="on" />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ item }}</v-list-item-title>
          <v-list-item-subtitle v-if="!availableActions.includes(item)">
            Временно добавленное право
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>

      <template #no-data>
        <v-list-item @click="addTempAction">
          <v-list-item-content>
            <v-list-item-title>
              Право "
              <strong>{{ search }}</strong>
              " не найдено
              <br />
              <span class="text-subtitle-2">
                Нажмите, чтобы временно добавить право
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
    <v-text-field
      label="Access token"
      :value="access"
      readonly
      outlined
      dense
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      access: null,
      search: '',
    };
  },
  computed: {
    ...mapGetters('AUTH', ['actions', 'availableActions']),
    allActions() {
      const temp = this.actions.filter(
        item => !this.availableActions.includes(item),
      );
      return [...temp, ...this.availableActions];
    },
  },

  async created() {
    this.access = await this.$store.dispatch('AUTH/getAccessToken');
  },

  methods: {
    changeActions(actions) {
      this.$store.commit('AUTH/debugChangeActions', actions);
    },
    addTempAction() {
      this.$store.commit('AUTH/debugChangeActions', [
        ...this.actions,
        this.search,
      ]);
      this.search = '';
    },
  },
};
</script>
