<template>
  <v-card class="debugger" :style="position" elevation="5">
    <v-tabs v-model="tab">
      <v-tab href="#auth"> Auth </v-tab>
      <v-tab href="#api"> Api </v-tab>
    </v-tabs>
    <v-divider />
    <v-card-text>
      <v-tabs-items v-model="tab">
        <v-tab-item value="auth">
          <Auth class="py-1" />
        </v-tab-item>
        <v-tab-item value="api">
          <Api class="py-1" />
        </v-tab-item>
      </v-tabs-items>
      <v-row align-end>
        <v-spacer />
        <v-col cols="12" sm="6">
          <v-btn outlined block @click="$emit('close')"> Закрыть </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import Auth from './Auth';
import Api from './Api';
export default {
  components: { Auth, Api },
  data() {
    return {
      tab: null,
      position: {
        right: 0,
        bottom: 0,
      },
    };
  },
  computed: {},

  methods: {
    handleMove() {
      if (this.position.right != null) {
        this.position = {
          left: 0,
          bottom: 0,
        };
      } else {
        this.position = {
          right: 0,
          bottom: 0,
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.debugger {
  position: fixed;
  width: 500px;
  &__header {
    cursor: move;
  }
}
</style>
