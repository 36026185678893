<template>
  <div>
    <v-select
      v-model="selectedMethod"
      class="mb-3"
      outlined
      label="Actions"
      :items="methods"
      item-text="name"
      return-object
      hide-details
      :disabled="isLoading"
    />
    <template v-if="selectedMethod">
      <h3 v-if="fields.length > 0" class="mb-3">Arguments:</h3>
      <v-row>
        <v-col v-for="field in fields" :key="field" cols="6">
          <v-text-field
            v-model="args[field]"
            :label="field"
            outlined
            dense
            hide-details
          />
        </v-col>
        <v-col cols="6">
          <v-btn block depressed color="primary" @click="execute">
            execute method
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <template v-if="isLoading">
      <h3 class="mt-3 mb-1">Loading...</h3>
    </template>
    <template v-if="result">
      <h3 class="mt-3 mb-1">Result:</h3>
      <div>{{ result }}</div>
    </template>
  </div>
</template>

<script>
// babel wildcard needs explicit paths
import * as SERVICES from '../../api/services';

export default {
  data() {
    return {
      selectedMethod: null,
      args: {},
      result: null,
      isLoading: false,
    };
  },
  computed: {
    methods() {
      return Object.entries(SERVICES)
        .map(([service, methods]) => {
          return [
            { header: 'Service: ' + service },
            ...Object.entries(methods).map(([name, method]) => ({
              group: service,
              name,
              value: service + '/' + name,
              method,
            })),
            { divider: true },
          ];
        })
        .flat();
    },
    fields() {
      if (!this.selectedMethod) return;
      return getFunctionArgs(this.selectedMethod.method);
    },
  },
  watch: {
    selectedMethod() {
      this.args = {};
      this.result = null;
    },
  },

  methods: {
    async execute() {
      this.result = null;
      this.isLoading = true;
      try {
        this.result = await this.selectedMethod.method(
          ...Object.values(this.args).map(JSON.parse),
        );
      } catch (error) {
        this.result = error;
      } finally {
        this.isLoading = false;
      }
    },
  },
};

function getFunctionArgs(func) {
  const STRIP_COMMENTS = /((\/\/.*$)|(\/\*[\s\S]*?\*\/))/gm;
  const FN_ARGS = /^[^(]*\(\s*([^)]*)\)/m;

  const fnText = func.toString().replace(STRIP_COMMENTS, '');
  const args = fnText.match(FN_ARGS);

  if (args[1]?.trim() === '') return [];

  return args[1].split(',').map(item => item.trim());
}
</script>
